<template>
    <div class="search-result-box">
        <div class="background"></div>
        <div class="flex x-between container top-content">
            <router-link :to="{ path: '/' }" class="return-index t-c fs14 ml20 mt30 mb50">返回首页</router-link>
            <div class="login-btn mt40" v-if="!(userInfo || {}).Mobile">
                <a class="color333 fs14" href="javascript:;" @click="handleLoginEvent">登录</a>
                <span class="pl5 pr5">|</span>
                <a class="color333 fs14" href="javascript:;" @click="handleRegisterEvent">注册</a>
            </div>
            <div class="mt40" v-else>
                <a-dropdown placement="bottomCenter">
                    <a style="height:32px;" class="flex y-center ant-dropdown-link" @click="e => e.preventDefault()">
                        <a-avatar icon="user" :src="userInfo.Avatar || require('@/assets/images/person-default.png')" />
                        <span class="ml10 color666 fs16">{{ userInfo.RealName }}</span>
                        <a-icon class="color333 ml10" type="down" />
                    </a>
                    <a-menu slot="overlay" style="max-width:100px;">
                        <a-menu-item class="t-c">
                            <a target="_blank" :href="personal_url + committee_id">个人中心</a>
                        </a-menu-item>
                        <a-menu-item class="t-c">
                            <a target="_blank" :href="mini_url + 'user/index/' + committee_id">管理中心</a>
                        </a-menu-item>
                        <a-menu-item class="t-c">
                            <a @click="handlelogoutEvent" href="javascript:;">安全退出</a>
                        </a-menu-item>
                    </a-menu>
                </a-dropdown>

            </div>
        </div>
        
        <!--    内容部分    -->
        <div class="content container">
            <!-- banner -->
            <div class="banner" v-if="pic_banner">
                <img :src="pic_banner | urlFilter(750)" alt="">
            </div>

            <!--    搜索条件    -->
            <div class="table-page-search-wrapper">
                <a-form layout="inline">
                    <a-row :gutter="[10,10]">
                        <a-col :xl="6" :lg="12" :xs="12" style="text-align: left;">
                            <a-form-item label="检索项目">
                                <a-input v-model="searchParams.project_keywords" placeholder="按项目/完成人/完成人单位" />
                            </a-form-item>
                        </a-col>
                        <a-col :xl="6" :lg="12" :xs="12" style="text-align: left;">
                            <a-form-item label="奖项级别">
                                <a-select v-model="searchParams.awards_id" placeholder="请选择奖项级别" allowClear>
                                    <a-select-option v-for="(item,index) in rankList" :value="item.id">{{ item.awards_name }}</a-select-option>
                                </a-select>
                            </a-form-item>
                        </a-col>
                        <a-col :xl="6" :lg="12" :xs="12" style="text-align: left;">
                            <a-form-item label="推荐单位">
                                <a-select v-model="searchParams.related_company" placeholder="请选择推荐单位" allowClear>
                                    <a-select-option v-for="(item,index) in companyList" :key="index" :value="item.related_company">
                                        {{ item.related_company }}
                                    </a-select-option>
                                </a-select>
                            </a-form-item>
                        </a-col>
                        <a-col :xl="6" :lg="12" :xs="12" style="text-align: left;position: relative;top: 4px;">
                            <a-button type="primary" @click="handleSearch" style="">搜索</a-button>
                            <a-button style="margin-left: 8px" @click="() => this.searchParams = {}">重置</a-button>
                        </a-col>
                    </a-row>
                </a-form>
            </div>



            <div class="list-content mt20">
                <div class="item-box" v-for="(item,index) in infoList" :key="index">
                    <template v-if="item.type == 1">
                        <div class="line flex x-left y-center">
                            <div class="key">奖项级别 :</div>
                            <div :class="['value', 'flex', 'y-center', 'x-left', 'strong', `theme${item.awards_id % 7}`]">{{ item.awards_name }} <span class="ait-icon fs20 med med-medal-fill"></span></div>
                        </div>
                        <div class="line flex x-left y-center">
                            <div class="key">项目编号 :</div>
                            <div class="value">{{ item.project_num }}</div>
                        </div>
                        <div class="line flex x-left y-center">
                            <div class="key">项目名称 :</div>
                            <div class="value strong">{{ item.project_name_cn }}</div>
                        </div>
                        <div class="line flex x-left y-center">
                            <div class="key">主要完成人 :</div>
                            <div class="value">{{ item.main_completed }}</div>
                        </div>
                        <div class="line flex x-left y-center">
                            <div class="key">主要完成人单位 :</div>
                            <div class="value">{{ item.main_company }}</div>
                        </div>
                        <div class="line flex x-left y-center">
                            <div class="key">推荐单位 :</div>
                            <div class="value">{{ item.related_company }}</div>
                        </div>
                    </template>
                    <template v-else>
                        <div class="line flex x-left y-center">
                            <div class="key">奖项级别 :</div>
                            <div class="value strong theme1">{{ item.awards_name }}</div>
                        </div>
                        <div class="line flex x-left y-center">
                            <div class="key">项目编号 :</div>
                            <div class="value">{{ item.project_num }}</div>
                        </div>
                        <div class="line flex x-left y-center">
                            <div class="key">拟授奖人 :</div>
                            <div class="value">{{ item.one_completd }}</div>
                        </div>
                        <div class="line flex x-left y-center">
                            <div class="key">工作单位 :</div>
                            <div class="value">{{ item.one_company }}</div>
                        </div>
                        <div class="line flex x-left y-center">
                            <div class="key">推荐常务理事 :</div>
                            <div class="value strong">{{ item.related_company }}</div>
                        </div>
                    </template>
                </div>
            </div>

            <div class="t-c mt30" v-if="pageinationParams.total > 0">
                <a-pagination :total="pageinationParams.total" v-model:current="pageinationParams.page"
                              :page-size="pageinationParams.limit" show-quick-jumper @change="getDataList"
                              :hideOnSinglePage="true" />
            </div>
        </div>
    </div>
</template>

<script>
import {handleBanerToPage} from "@/utils/jumpPageMethods";
import { mini_url, committee_id,personal_url } from '@/config';
import {mapState} from "vuex";
import storage from "store";

export default {
    name: "search-result",
    data(){
        return {
            personal_url,
            mini_url,
            committee_id,
            bannerList: [],
            searchParams : {

            },// 筛选条件
            companyList : [],// 推荐单位
            rankList : [],// 获奖级别
            pageinationParams : {
                page : 1,
                limit : 10,
                total : 0
            },
            infoList : [],
            pic_banner : ''
        }
    },
    computed: {
        ...mapState(['userInfo'])
    },
    created(){
        let { award_id , year } = this.$route.query;
        this._award_id = award_id;
        this._year = year;
        this.getBanner();// 获取banner
        this.getFilterType();// 获取筛选条件
        this.getDataList();// 获取列表数据
    },
    methods : {
        handleBanerToPage,
        getBanner(){
            this.request.post('GetBanner').then(res => {
                this.bannerList = res.data
            })
        },
        // 退出登录
        handlelogoutEvent() {
            storage.set('auth', '');
            storage.set('showSelected', false)
            storage.remove('subjectIds')
            storage.remove('operate_id')
            //this.$store.commit('updateState',{userInfo:null});
            window.location.href = mini_url+'site/logout/'+committee_id+'?return_url='+window.location.origin
        },
        // 登录
        handleLoginEvent() {
            window.location.href = this.mini_url + "unified/login?return_url=" + encodeURIComponent(window.location.href);
            console.log(window.location.href)
        },
        // 注册
        handleRegisterEvent() {
            window.location.href = this.mini_url + "unified/register?return_url=" + encodeURIComponent(window.location.href);
        },
        getFilterType(){
            let data = {
                award_id : this._award_id
            }
            let requestArr = [
                this.request.post('GetAwardsCompany' , data),// 推荐单位
                this.request.post('GetAwards' , data),// 获奖级别
            ];
            Promise.all(requestArr).then(res => {
                this.companyList = res[0].data;

                res[1].data = res[1].data.filter(item => item.count > 0);
                this.rankList = res[1].data;
            });
        },
        handleSearch(){
            console.log(this.searchParams)
            this.getDataList();
        },
        getDataList(){
            let data = {
                c_award_id: this._award_id,
                year : this._year,
                limit: this.pageinationParams.limit,
                page : this.pageinationParams.page,
                ...this.searchParams
            }
            data.awards_id && (delete data.c_award_id);
            this.request.post('GetProjectAwards',data).then(res => {
                this.infoList = res.data.list;
                this.pic_banner = res.data.pic_banner || 'https://files.sciconf.cn/medcon/2023/10/20231017/2023101709512886321479510.jpg!wx250';
                this.pageinationParams.total = parseInt(res.data.count);
            })
        }
    }
}
</script>

<style scoped lang="less">
    .table-page-search-wrapper {
        padding: 0 40px;
        /deep/ .ant-form-inline .ant-form-item{
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            margin-right: 0
        }
        /deep/ .ant-form-item-control-wrapper{
            flex: 1;
        }
    }

    .search-result-box{
        padding-bottom: 60px;
        .background {
            width: 100%;
            height: 408px;
            background: #FFFFFF;
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
        }
        .login-btn {
            height: 20px;
        }
        .return-index {
            width: 92px;
            height: 40px;
            border: 1px solid #1E51C9;
            border-radius: 19px;
            line-height: 38px;
            color: #1E51C9;
            cursor: pointer;
            display: block;
        }
    }
    .list-content{
        padding: 0 40px;
        .item-box{
            border: 1px solid #E5E5E5;
            border-radius: 4px;
            padding: 10px 20px;
            box-sizing: border-box;
            text-align: left;
            margin-bottom: 20px;
            .line{
                margin-bottom: 4px;
                .key{
                    color: #666666;
                }
                .value{
                    margin-left: 10px;
                    flex: 1;
                    color: #333333;
                    &.theme1{
                        color: #e1554f;
                    }
                    &.theme2{
                        color: #68c87a;
                    }
                    &.theme3{
                        color: #52a6ea;
                    }
                    &.theme4{
                        color: #3436C7;
                    }
                    &.theme5{
                        color: #975fe4;
                    }
                    &.theme6{
                        color: #223273;
                    }
                    &.theme7{
                        color: #FACC14;
                    }
                }
            }
        }
    }
    .banner{
        width: 100%;
        margin: 20px 0;
        img{
            width: 100%;
            height: 100%;
        }
    }
    @media screen and (max-width:768px) {
        .search-result-box {
            width: 100%;
            margin-top: -80px;

            .container {
                padding: 10px;
                margin-bottom: -20px;

                .mt40 {
                    margin-top: 35px;
                }

                .mb50 {
                    margin-bottom: 20px;
                }

                .return-index {
                    margin-left: 0;
                }
            }
        }
        .table-page-search-wrapper,.list-content{
            padding: 0 10px;
        }
        /deep/ .ant-form-item-label{
            display: none !important;
        }
        .item-box{
            margin-bottom: 10px !important;
            padding: 10px !important;
            .line{
                margin-bottom: 0 !important;
            }
            .y-center{
                align-items: start;
            }
        }
    }
</style>